import { Routes } from "@angular/router";

export const content: Routes = [
    {
        path:'',
        loadChildren: () => import("../../admin/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path:'admin-dashboard',
        loadChildren: () => import("../../admin/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path: "galery",
        loadChildren: () => import("../../admin/pages/galery/galery.module").then((m) => m.GaleryModule),
    },
    {
        path: "buy",
        loadChildren: () => import("../../admin/pages/buy/buy.module").then((m) => m.BuyModule),
    },
   
    {
        path: "rent",
        loadChildren: () => import("../../admin/pages/rent/rent.module").then((m) => m.RentModule),
    },
    {
        path: "torent",
        loadChildren: () => import("../../admin/pages/torent/torent.module").then((m) => m.TorentModule),
    },
    {
        path: "sell",
        loadChildren: () => import("../../admin/pages/sell/sell.module").then((m) => m.SellModule),
    },
    {
        path: "blog",
        loadChildren: () => import("../../admin/pages/blog/blog.module").then((m) => m.BlogModule),
    },
    {
        path: "crm",
        loadChildren: () => import("../../admin/pages/crm/crm.module").then((m) => m.CrmModule),
    },
    {
        path: "customer",
        loadChildren: () => import("../../admin/pages/customer/customer.module").then((m) => m.CustomerModule),
    },
    {
        path: "code-promo",
        loadChildren: () => import("../../admin/pages/code-promo/code-promo.module").then((m) => m.CodePromoModule),
    },
    {
        path: "setting-vehicule",
        loadChildren: () => import("../../admin/pages/setting-vehicule/setting-vehicule.module").then((m) => m.SettingVehiculeModule),
    },
   
    {
        path: "**",
        loadChildren: () => import("../../admin/pages/error/error.module").then((m) => m.ErrorModule),
    },
];
